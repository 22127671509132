<template>
  <div class="body">
    <!-- 引用文件配置表 -->
    <Head />
    <Header class="title" title="引用文件配置表" beforeTitle="引用文件配置表" index="首页" />
    <div class="content">
      <div class="topUsers">
        <div>
          <el-input
            clearable
            v-model="quoteParams.name"
            placeholder="请输入模板名称"
            @keyup.enter.native="handleSearch()"
          ></el-input>
          <!-- <el-input
            clearable
            v-model="quoteParams.keywords"
            placeholder="请输入填充类型"
            @keyup.enter.native="searchType()"
          ></el-input>-->
          <el-button class="search" @click="handleSearch()">查询</el-button>
        </div>
        <el-button class="search" @click="addQuote('')">新增引用</el-button>
      </div>
      <el-table
        v-loading="loading"
        element-loading-text="正在加载中···"
        :data="quote.data"
        style="width: 100%"
      >
        <el-table-column prop="name" label="模板名称" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.name ? scope.row.name : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="new_file_name" label="生成文件名称" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.new_file_name ? scope.row.new_file_name : "-" }}</template>
        </el-table-column>
        <el-table-column prop="data_type" label="数据填充类型" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span v-if="scope.row.data_type == 1">表格</span>
            <span v-if="scope.row.data_type == 2">替换</span>
          </template>
        </el-table-column>
        <el-table-column prop="url" label="状态" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span v-if="scope.row.status" style="color: #0F38FF;">开启</span>
            <span v-if="!scope.row.status" style="color: #CACDDF;">关闭</span>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="创建时间 " :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.create_time ? scope.row.create_time : "-" }}</template>
        </el-table-column>
        <el-table-column prop="operation" label="操作" width="370px">
          <template slot-scope="scope">
            <el-button class="edit" size="mini" @click="addQuote(scope.row.id)">编辑</el-button>
            <el-button class="edit" size="mini" @click="fileLoad(scope.row.url)">附件下载</el-button>
            <el-button
              style="margin-left:10px"
              type="primary"
              class="del"
              size="mini"
              @click="handleDelete(scope.row.id)"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block" v-if="quote.total > 10">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="quoteParams.page"
          :page-sizes="[2, 10, 20, 40]"
          :page-size="quoteParams.limit"
          layout="sizes, prev, pager, next,total,jumper"
          :total="quote.total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import gqbUrl from '../../components/global.vue'
import Head from '../../components/headBlue/index.vue'
export default {
  data () {
    return {
      url: gqbUrl.userSite,
      quoteParams: {
        keywords: '',
        page: 1,
        limit: 10,
      },
      quote: {},
    }
  },
  components: {
    Head,
  },
  created () {
    this.getList()
  },
  methods: {
    getList () {
      this.axios.get('/api/quote/list', { params: { ...this.quoteParams } }).then((res) => {
        this.quote = res.data
      })
    },
    addQuote (id) {
      this.$router.push({ path: '/quote_file/add_quote', query: { id: id } })
    },
    handleSearch () {
      this.getList()

    },
    // searchType () {
    //   this.axios.get('/api/quote/select_quote', { params: { keywords: this.keywords } }).then((res) => {
    //     this.quote = res.data
    //   })
    // },
    handleDelete (id) {
      this.$confirm("确定删除此项目数据，删除后无法恢复！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          this.axios.delete("/api/quote/delete", {
            params: {
              id: id,
            },
          }).then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getList()
          }).catch((err) => {
            this.$message.error(err.message)
          });
        })
        .catch((res) => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    fileLoad (url) {
      window.open(this.url + url, '_blank')
    },
    handleSizeChange (val) {
      this.getList(this.quoteParams.limit = val)
    },
    handleCurrentChange (val) {
      this.getList(this.quoteParams.page = val)
    },
  },
}
</script>

<style scoped>
.body {
  background: #f2f3f8;
  min-height: 100%;
}
.title {
  padding: 0 30px;
  margin-top: 20px;
}
.content {
  margin: 30px;
}
.body::-webkit-scrollbar {
  width: 0; /* 隐藏滚动条 */
}
</style>